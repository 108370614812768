import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { isPlatformBrowser, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Output, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-height-range-slider',
  standalone: true,
  imports: [
    NgIf,
    NgxSliderModule
  ],
  templateUrl: './height-range-slider.component.html',
  styleUrl: './height-range-slider.component.scss'
})
export class HeightRangeSliderComponent {
  public isBrowser = false;
  @Output() heightEvent: EventEmitter<{from: number, to: number}> = new EventEmitter();

  value: number = 140;
  highValue: number = 160;
  options: Options = {
    floor: 140,
    ceil: 220,
    ticksArray: [140, 220]
  };

  constructor( @Inject(PLATFORM_ID) private platformId: Object){
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  valueChange(event): void {
    this.heightEvent.emit({from: this.value, to: ++this.highValue});
  }
}
