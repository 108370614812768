

<div class="custom-slider" *ngIf="isBrowser">
  <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (userChangeEnd)="valueChange($event)"></ngx-slider>
  <div class="selected-value">
    <div class="value from">
      <span class="label-font">{{value}} cm</span>
    </div>
    /
    <div class="value from"><span class="label-font">{{highValue}} cm</span></div>
  </div>
</div>
