<ng-container *transloco="let t;">
  <div class="filter">
    <div class="filter-header">
      <span class="font-main-small">{{t('Fan_Filter_Tittle')}}</span>
      <button (click)="clearFilter()" class="btn btn-light btn-color-grey">{{t('Fan_Filter_Clear_All')}} <span class="item-count item-count-large text text-bold has-value">12</span></button>
      <button class="close-btn">
        <app-icons name="close_1" [width]="40" [height]="40" fillColor="#6A49FA" (click)="closeEvent.emit()"></app-icons>
      </button>
    </div>
    <div class="divider"></div>
    <div class="filter-content">
      <div class="content-type">
        <div class="title">
          <span class="profile-name">Type of Content <span class="highlighted">NSFW</span></span>
          <button (click)="clearContentType()" class="btn btn-light btn-color-grey">Clear </button>
          <span class="item-count text text-bold " [ngClass]="{'has-value': !!contentTypeCounter}">{{contentTypeCounter}}</span>
        </div>

        <app-creator-content-type
          [contentCollect]="contentCollect.contentList"
          [isFunFilter]="true" [className]="'compact'"
          [resetEvent]="contentResetSubject"
          (contentType)="onUpdateContentType($event)"

        ></app-creator-content-type>
      </div>
      <div class="gender">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Gender', count: 0 }"></ng-container>
        <app-select-style [isArray]="true" [translate]="true"
          [data]="contentCollect.genderList"
          [isFunFilter]="true" [className]="'compact'"
          [title]="'gender'"
          [header]="'Gender'"
          (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="butt">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Butt', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true"
          [data]="contentCollect.buttList"
          [isFunFilter]="true" [className]="'compact'"
          [title]="'buttSize'"
          [header]="'Gender'"
          (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="breasts">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Breasts', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true"
        [data]="contentCollect.breastList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'breastsSize'"
        [header]="'Breasts'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="breasts">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Breasts', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true"
        [data]="contentCollect.penisList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'penisSize'"
        [header]="'Penis'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="hair-style">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Hair Style', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true"
        [data]="contentCollect.hairStyleList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'hairStyle'"
        [header]="'Hair Style'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="hair-length">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Hair length', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true" [iconName]="'hair_length'"
        [data]="contentCollect.hairLengthList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'hairLength'"
        [header]="'Hair length?'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="hair-length">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Skin tone', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true" [iconName]="'hair_length'"
        [data]="contentCollect.skinToneList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'skinTone'"
        [header]="'Skin tone'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="eye-color">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Eye Color', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true" [iconName]="'hair_length'"
        [data]="contentCollect.eyeStyleList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'eyeColor'"
        [header]="'What your eye color?'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>

      <div class="hair-color">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Hair Color', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true" [iconName]="'hair_length'"
        [data]="contentCollect.heirColorList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'hairColor'"
        [header]="'What your hair color?'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>



      <div class="body-options">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Additional', count: 0 }"></ng-container>
        <app-body-options
        [bodyOptions]="{ tattoos: contentCollect.tattoos, piercing: contentCollect.piercing }"
        [isFunFilter]="true" [className]="'compact'"
        (bodyOptionsEvent)="onSelectBodyOptions($event)"></app-body-options>
      </div>


      <div class="height">
        <div class="title">
          <span class="profile-name">Hight</span>
          <button (click)="clearContentType()" class="btn btn-light btn-color-grey">Clear </button>
        </div>
        <ng-container>
          <app-height-range-slider (heightEvent)="setHightEvent($event)"></app-height-range-slider>
        </ng-container>
      </div>

      <div class="body-type">
        <ng-container *ngTemplateOutlet="titleTemplate; context: { name: 'Body Type', count: 0 }"></ng-container>
        <app-select-style [isArray]="true"  [translate]="true" [iconName]="'hair_length'"
        [data]="contentCollect.bodyTypeList"
        [isFunFilter]="true" [className]="'compact'"
        [title]="'bodyType'"
        [header]="'What your body type?'"
        (onSelectValue)="onUpdateAnswerObj($event)"></app-select-style>
      </div>


    </div>

    <button  class="btn btn-range btn-full-width action-text">
      SAVE
    </button>
  </div>
</ng-container>

<ng-template #titleTemplate let-name="name" let-count="count">
  <div class="title">
    <span class="profile-name">{{ name }}</span>
    <span *ngIf="count !== null" class="item-count text text-bold">{{ count }}</span>
  </div>
</ng-template>
