<ng-container>
  <aside id="navigation">
    <header id="navigation-header">
      <app-logo routerLink="/"></app-logo>
    </header>
    <nav id="navigation-list">
      <ul>
        <li *ngIf="user != null" class="navigation-account">
          <app-user-popup [user]="user"></app-user-popup>
        </li>
        <li *ngFor="let item of menuItems">
          <ng-container
            [ngTemplateOutlet]="item | navigationTemplate: templates"
            [ngTemplateOutletContext]="{item: item}"
          >
          </ng-container>
        </li>

      </ul>
    </nav>
  </aside>
  <aside id="mobile-navigation">
    <nav id="mobile-navigation-list">
      <ul *ngIf="isLoggedIn; else mobileLogin;" (click)="handleSubmenuClick($event)">
        <li *ngFor="let item of mobileMenuItems">
          <ng-container
          [ngTemplateOutlet]="item | navigationTemplate: templates:true"
          [ngTemplateOutletContext]="{item: item}"
        ></ng-container>
        </li>
      </ul>
    </nav>
  </aside>
</ng-container>
<ng-template #mobileLogin>
  <div class="mobile-login">
    <button class="btn btn-primary" (click)="loginFn()">
      <app-icons name="fire" [width]="32" [height]="32" fillColor="#ffffff"></app-icons>
      <span *transloco="let t;">{{t('login_now')}}</span>
    </button>
  </div>
</ng-template>
<ng-template #desktopRegular let-item="item">
  <a class="navigation-list-item" id="{{item.name}}" routerLinkActive="navigation-list-item-active" routerLink="{{item.routerLink}}" (click)="handleMenuItemEvent($event)">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#131315"></app-icons>
    <span class="navigation-list-item-title" *transloco="let t;">{{t(item.name)}}</span>
    <div *ngIf="item.counter > 0" class="navigation-list-item-count">
      {{item.counter}}
    </div>
  </a>
</ng-template>

<!--  -->
<ng-template #homeFilterLink let-item="item">
  <a #homeElement class="navigation-list-item home" id="menu_Home" routerLinkActive="navigation-list-item-active" (click)="handleMenuItemEvent($event)">
    <div class="home-main">
      <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#131315"></app-icons>
      <span *transloco="let t;">{{t(item.name)}}</span>
      <button class="post-filter-btn" (click)="postFiltersByTag($event, homeElement)">
        <app-icons name="settings_slider" [width]="28" [height]="28" fillColor="#6A49FA"></app-icons>
      </button>
    </div>
  </a>
</ng-template>

<!-- NEW home BTN with creator filters -->
<!-- <ng-template #homeFilterLink let-item="item">
  <a #homeElement class="navigation-list-item home" id="new-home" routerLinkActive="navigation-list-item-active" routerLink="{{item.routerLink}}">
    <div class="home-main">
      <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#131315"></app-icons>
      <span *transloco="let t;">{{t(item.name)}}</span>
      <button (click)="setDesires($event, homeElement)">
        <app-icons name="settings_slider" [width]="28" [height]="28" fillColor="#BDBDE5"></app-icons>
      </button>
    </div>
    <div class="home-desires" (click)="openFilter()">
      <app-icons name="logo" [width]="28" [height]="28" fillColor="#ffffff"></app-icons>
      <span *transloco="let t;">My Desires</span>
      <div class="navigation-list-item-count">
        6
      </div>
      <app-icons name="settings_slider" [width]="28" [height]="28" fillColor="#ffffff"></app-icons>
    </div>
  </a>
</ng-template> -->
<!--  -->

<ng-template #desktopCreator let-item="item">
  <div class="additional-menu-item navigation-list-item" id="become-a-creator-btn" *ngIf="!isContentCreator" (click)="becomeCreatorPress()">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#6A49FA"></app-icons>
    <span *transloco="let t;">{{t(item.name)}}</span>
  </div>
</ng-template>
<ng-template #desktopPost let-item="item">
  <div class="additional-menu-item navigation-list-item" id="new-post-btn" *ngIf="isLoggedIn" (click)="newPostPress()">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28"></app-icons>
    <span *transloco="let t;">{{t(item.name)}}</span>
  </div>
</ng-template>
<ng-template #desktopBoost let-item="item">
  <a class="additional-menu-item navigation-list-item" id="become-a-creator-btn" *ngIf="isContentCreator" href="{{item.routerLink}}" target="_blank">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#6A49FA"></app-icons>
    <span *transloco="let t;">{{t(item.name)}}</span>
  </a>
</ng-template>
<ng-template #loginFef let-item="item">
  <div class="additional-menu-item navigation-list-item" id="new-post-btn" *ngIf="!isLoggedIn" (click)="loginFn(item.routerLink)">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28"></app-icons>
    <span *transloco="let t;">{{t(item.name)}}</span>
  </div>
</ng-template>
<ng-template #mobileRegular let-item="item">
  <a class="mobile-navigation-list-item" routerLinkActive="mobile-navigation-list-item-active" routerLink="{{item.routerLink}}">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#131315"></app-icons>
    <div *ngIf="item.counter > 0" class="mobile-navigation-list-item-count">
      {{item.counter}}
    </div>
  </a>
</ng-template>
<ng-template #mobileCreator let-item="item">
  <div class="additional-menu-item navigation-list-item" id="become-a-creator-mob-btn" *ngIf="!isContentCreator" (click)="becomeCreatorPress()">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28" fillColor="#6A49FA"></app-icons>
  </div>
</ng-template>
<ng-template #mobileSettings let-item="item">
  <a class="additional-menu-item navigation-list-item" id="settings-mob-btn">
    <img [attr.src]="avatarUrl" alt="avatar">
  </a>
</ng-template>
<ng-template #mobilePost let-item="item">
  <div class="additional-menu-item navigation-list-item" id="new-post-mob-btn" *ngIf="isLoggedIn" (click)="newPostPress()">
    <app-icons name="{{item.icon.name}}" [width]="28" [height]="28"></app-icons>
  </div>
</ng-template>
<app-users-subnavigation [ngClass]="{'active': isSubmenuVisible}"
                        (obBecomeCreatorEvent)="becomeCreatorPress()"
                        (subNavStatus)="this.isSubmenuVisible = false;"
                        [userData]="user"
                        [avatarUrl]="avatarUrl">
</app-users-subnavigation>


<!-- <app-dialog #dialogRef [dismissible]="false">
  <app-fun-filter (closeEvent)="filterDialog.close()"></app-fun-filter>
</app-dialog> -->

<app-dialog #dialogTagsFilterRef [dismissible]="false" [size]="560">
  <app-tags-filter (closeEvent)="dialogTagsFilterRef.close()"></app-tags-filter>
</app-dialog>
