<ng-container *transloco="let t;">
  <div class="filter">
    <div class="filter-header">
      <span class="font-main-small">{{t('Tags_Filter_Tittle')}}</span>
      <button (click)="clearFilter()" class="btn btn-light btn-color-grey">{{t('Tags_Filter_Clear_All')}} </button>
      <button class="close-btn">
        <app-icons name="close_1" [width]="40" [height]="40" fillColor="#6A49FA"
          (click)="closeEvent.emit()"></app-icons>
      </button>
    </div>
    <div class="filter-content">
      <div class="title">
        <span class="profile-name">{{ t('Tags_Filter_Tags') }} <span class="secondary-text text-color-secondary"> {{tagsList.length}}/{{maxPostTags}}</span></span>
        <span class="item-count text text-bold" [ngClass]="{'has-value': !!tagsList.length}">{{tagsList.length}}</span>
      </div>
      <app-search [placeholder]="searchPlaceholder.searchTag" (searchRequest)="onSearchEventHandler($event)"></app-search>
      <div class="selected-tags">
        <app-tag
          *ngFor="let tag of tagsList; let i = index"
          [isEditable]="true"
          [tagName]="tag"
          (removeTag)="removeTag(i)">
        </app-tag>
      </div>

      <div class="recommended-tags">
        <app-tag
          *ngFor="let tag of recommendationTagsList"
          [tagName]="tag"
          [isRecommendation]="true"
          (selectTag)="addSelectedTag($event)">
      </app-tag>
      </div>

      <div class="title">
        <span class="profile-name">{{ t('Tags_Filter_Most_Popular_Tags') }}</span>
      </div>
      <app-select-style  [translate]="false" [isArray]="true" [data]="popularTags" [title]="'tags'" [header]="'tags'" [className]="'middle'" [allowMultipleSelections]="true"
        [isFunFilter]="true" [selected]="tagsList" (onSelectValue)="onUpdateAnswerObj($event)">
      </app-select-style>
    </div>
    <button (click)="onSave()" class="btn btn-range btn-full-width action-text">
      {{t('Tags_Filter_Save')}}
    </button>
  </div>
</ng-container>
