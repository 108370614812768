import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { BodyOptionsComponent } from 'src/app/modules/agency-onboarding/components/body-options/body-options.component';
import { CreatorContentTypeComponent } from 'src/app/modules/agency-onboarding/components/creator-content-type/creator-content-type.component';
import { HeightRangeSliderComponent } from 'src/app/modules/agency-onboarding/components/height-range-slider/height-range-slider.component';
import { SelectStyleComponent } from 'src/app/modules/agency-onboarding/components/select-style/select-style.component';
import { CreatorOnboardingService } from 'src/app/modules/agency-onboarding/creator-onboarding.service';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import {TranslocoDirective} from "@ngneat/transloco";

@Component({
  selector: 'app-fun-filter',
  standalone: true,
    imports: [
        CommonModule,
        IconsComponent,
        CreatorContentTypeComponent,
        SelectStyleComponent,
        BodyOptionsComponent,
        HeightRangeSliderComponent,
        TranslocoDirective
    ],
  templateUrl: './fun-filter.component.html',
  styleUrl: './fun-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class FunFilterComponent implements OnInit{
  @Output() closeEvent: EventEmitter<void> = new EventEmitter();
  public contentCollect;
  public contentResetSubject = new Subject<void>();
  restObj: any = {}
  constructor(
    private onboarding: CreatorOnboardingService,
    private cdr: ChangeDetectorRef
  ){}
  ngOnInit(): void {
    this.contentCollect = this.onboarding.getContentList();
  }

  clearFilter() { }

  clearContentType() {
    this.restObj.contentType = null;
    this.contentResetSubject.next();
    this.cdr.detectChanges()
  }

  public onUpdateContentType(updates: { [key: string]: string[] }): void {
    // this.requestBody = updates;
    console.log('onUpdateContentType', updates, );
    this.restObj.contentType = updates;
  }

  get contentTypeCounter() {
    if(!this.restObj.contentType) return 0;
    let totalCount = 0;

    for (const key in this.restObj.contentType) {
      if (this.restObj.contentType.hasOwnProperty(key)) {
        totalCount += this.restObj.contentType[key].length;
      }
    }

    return totalCount
  }

  onUpdateAnswerObj(e: any) {

    console.log('onUpdateAnswerObj', e);

  }

  public onSelectBodyOptions(value: {tattoos: boolean, piercing: boolean}): void {
    // this.answerObj.tattoos = value.tattoos;
    // this.answerObj.piercing = value.piercing;
    // this.requestBody = value;
    console.log('onSelectBodyOptions', value);

  }

  setHightEvent(value: {from: number, to: number}) {
    console.log('setHightEvent', value);

  }
}
