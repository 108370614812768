import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { SearchComponent, SearchPlaceholder } from '../search/search.component';
import { TagComponent } from '../tag/tag.component';
import { PostsService } from 'src/app/service/posts.service';
import { take } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SelectStyleComponent } from 'src/app/modules/agency-onboarding/components/select-style/select-style.component';
import {
  StorageDataKey,
  StorageService,
} from 'src/app/service/storage.service';
import {TranslocoDirective} from "@ngneat/transloco";

@Component({
  selector: 'app-tags-filter',
  standalone: true,
    imports: [
        CommonModule,
        IconsComponent,
        SearchComponent,
        TagComponent,
        SelectStyleComponent,
        TranslocoDirective,
    ],
  templateUrl: './tags-filter.component.html',
  styleUrl: './tags-filter.component.scss',
})
export class TagsFilterComponent implements OnInit {
  @Output() closeEvent: EventEmitter<void> = new EventEmitter();
  readonly searchPlaceholder = SearchPlaceholder;
  public tagsList = [];
  public recommendationTagsList = [];
  public popularTags = [];
  public maxPostTags = 25;

  @ViewChild(SearchComponent) appSearchComponent!: SearchComponent;

  constructor(
    private postService: PostsService,
    private storage: StorageService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    if (this.storage.getLocalJsonData(StorageDataKey.postTagsFilter))
      this.tagsList = [
        ...this.storage.getLocalJsonData(StorageDataKey.postTagsFilter),
      ];
    this.postService
      .getTagsPopularList(9, 1)
      .pipe(take(1))
      .subscribe((data) => {
        this.popularTags = data.values.map((item) => item.text);
      });
  }

  public onUpdateAnswerObj(e: any): void {
    e.tags.forEach((item) => {
      if (!this.tagsList.includes(item)) {
        if(this.tagsList.length === this.maxPostTags )return;
        this.tagsList = [...this.tagsList, item];
      }
    });

    this.tagsList = this.tagsList.filter((tag) => {
      const isInValues = e.tags.map((v) => v).includes(tag);
      const isInPopularTags = this.popularTags.map((p) => p).includes(tag);
      return isInValues || !isInPopularTags;
    });
  }

  public clearFilter(): void {
    this.recommendationTagsList = [];
    this.tagsList = [];
  }

  public onSearchEventHandler(e: any): void {
    if (!e) {
      this.recommendationTagsList = [];
      return;
    }
    this.postService
      .getTagsRecommendationList(e)
      .pipe(take(1))
      .subscribe((data) => {
        this.recommendationTagsList = data.values.filter(
          (tag) => !this.tagsList.includes(tag)
        );
      });
  }

  public removeTag(index: number): void {
    if (this.recommendationTagsList.length) {
      this.recommendationTagsList.push(this.tagsList[index]);
    }
    this.tagsList = this.tagsList.filter((_, i) => i !== index);
  }

  public addSelectedTag(tagName: string): void {
    if (this.tagsList.includes(tagName) || this.tagsList.length === this.maxPostTags) return;
    this.tagsList = [...this.tagsList, tagName];
    this.recommendationTagsList = this.recommendationTagsList.filter(
      (tag) => !this.tagsList.includes(tag)
    );
    this.cdr.detectChanges();
    if (!this.recommendationTagsList.length) {
      this.appSearchComponent.resetForm();
    }
  }

  public onSave(): void {
    this.storage.setLocalJsonData(StorageDataKey.postTagsFilter, this.tagsList);
    this.closeEvent.emit();
    this.postService.clearPosts();
    this.postService.feedReloadSubject.next(true);
  }
}
