<div class="popup-menu-container" *transloco="let t;">
  <button class="account" type="button" (click)="toggleMenu()">
    <ng-container *ngTemplateOutlet="account"></ng-container>
  </button>

  <div class="popup-menu" [class.open]="isOpen">
      <div class="account">
        <ng-container *ngTemplateOutlet="account"></ng-container>
      </div>
    <div class="account__stat">
      <span class="account__stat-item pointer" *ngIf="user.isCreator" (click)="showFollowing()"><span>{{user.countFollowers}}</span> {{t('followers')}}</span>
      <span class="account__stat-item pointer" (click)="showFollows()"><span>{{user.countFollows}}</span> {{t('followings')}}</span>
    </div>
    <div class="divider"></div>
    <a *ngIf="user.isCreator" class="popup-menu__link" routerLink="/my-profile" routerLinkActive="active" (click)="toggleMenu()">
      <app-icons name="profile" width="28" height="28" fillColor="#131515"></app-icons>
      <span>{{t('My_profile')}}</span>
    </a>
    <a class="popup-menu__link disabled" routerLink="">
      <app-icons name="lists" width="28" height="28" fillColor="#131515"></app-icons>
      <span>{{t('My_lists')}}</span>
    </a>
    <a class="popup-menu__link" routerLink="/settings/referals">
      <app-icons name="referral" width="28" height="28" fillColor="#131515"></app-icons>
      <span>{{t('My_referrals')}}</span>
    </a>
    <a class="popup-menu__link" routerLink="/settings" routerLinkActive="active" (click)="toggleMenu()">
      <app-icons name="settings" width="28" height="28" fillColor="#131515"></app-icons>
      <span>{{t('Settings')}}</span>
    </a>
    <div class="divider"></div>
    <a class="popup-menu__link disabled">
      <app-icons name="eye" width="28" height="28" fillColor="#131515"></app-icons>
      <span>{{t('Help_and_Support')}}</span>
    </a>
    <div class="divider"></div>
    <a class="popup-menu__link" (click)="logout()">
      <app-icons name="logout" width="28" height="28" fillColor="#131515"></app-icons>
      <span>{{t('Log_Out')}}</span>
    </a>
  </div>
</div>
<ng-template #account>
  <app-profile-avatar [url]="user.avatarUrl" [size]="'navigation'"></app-profile-avatar>
  <div class="account__wrapper">
    <div class="account__info">
      <div class="account__info-row">
        <strong *ngIf="user?.creatorDetails?.displayName != null">{{user.creatorDetails.displayName}}</strong>
        <app-icons *ngIf="user?.creatorDetails?.displayName != null && user.kycVerified" name="verify" [width]="14" [height]="14"></app-icons>
      </div>
      <div class="account__info-row">
        <span class="nickname">{{'@' + user.nickname}}</span>
        <app-icons *ngIf="user?.creatorDetails?.displayName == null && user.kycVerified" name="verify" [width]="14" [height]="14"></app-icons>
      </div>
    </div>
  </div>
  <app-icons class="arrow" [ngClass]="{'opened' : isOpen}" name="arrow" [width]="21" [height]="21" [rotate]="90"  [fillColor]="'#989898'"></app-icons>
</ng-template>
<app-dialog #statsDialogRef>
  <app-subscriber-list [subscribers]="subList" (onSelect)="routeToProfile($event)"></app-subscriber-list>
</app-dialog>
